footer {
  margin: 9.4rem auto 0 auto;
}

.footer-left {
  margin-top: 30px;
}
.footer-links {
  display: flex;
  gap: 30px;
}
.list-name {
  margin-top: 2rem;
  width: 76%;
}
.list-name p,
.copyright p,
.footer-links p {
  color: #000000cc;
  font-size: 1.8rem;
  margin: 0;
}
.copyright p {
  text-align: right;
}

.footer a {
  color: black;
}
.list a p {
  font-weight: 400;
}
.apply-for-invite {
  text-align: center;
  padding: 100px 0 0px 0;
  position: relative;
  z-index: 1;
}
.footer-bg-block {
  margin-top: -110px;
}
.footer-content-inner {
  padding: 85px 0 30px 0;
}
.copyright-icon-block {
  padding-bottom: 40px;
}
.footer-top {
  background-color: #f8f9fb;
}
.footer-bottom {
  max-width: 1200px;
  margin: 0 auto;
}
.footer-bg-block img.footer-bg-img {
  width: 100%;
  border-radius: 0;
}
.footer-right div {
  height: 100%;
}
.footer-right ul {
  height: 100%;
}
.footer-right ul li {
  padding: 10px 0;
}
.social-icons-ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: flex-end;
  -ms-flex-pack: flex-end;
  justify-content: flex-end;
}
.social-icons-ul .social-icons-li {
  background: #213047;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  transition: 0.3s;
  text-align: center;
  line-height: 40px;
  color: #fff;
}
.separation-line {
  border-bottom: 1px solid gray;
}
.apply-for-invite-txt p {
  margin-bottom: 30px;
}
.footer-content hr.separation-line {
  margin: 30px 0;
}
.copyright p {
  margin: 0;
}
.copyright-icon-block .row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.social-icons-ul li a {
  display: block;
}
.social-icons-ul li ~ li {
  padding-left: 15px;
}
.footer-right a:hover {
  color: #df0071;
}
.footer-wrapper.mar-t-8 {
  padding: 0;
}
.footer__wrapper {
  background: #fffcdc;

  position: relative;
}

.footer-inner {
  position: relative;
}

.footer-image {
  position: absolute;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  height: 100%;
}
@media (max-width: 1024px) {
  .footer-image {
    display: none;
  }
}

img.footer-members-img {
  height: 36px;
}
.footer-logo-text h2 {
  font-size: 4.4rem;
  color: #000;
}
.members-ul {
  display: flex;
}
.members-li {
  margin-right: 20px;
}
.footer-end {
  padding-top: 20px;
  padding-bottom: 30px;
  border-top: 1px solid #737373 !important;
}
.footer-end .row div {
  padding: 0;
}
.footer-items {
  padding-bottom: 40px !important;
}
.footer-items h1 {
  font-family: Prosto One;
  font-size: 3.6rem;
  font-weight: 400;
  color: #ffffff;
}
.footer-items p {
  color: #000000cc;
  font-size: 1.8rem;
  width: 70%;
}
.footer__banner--inner {
  text-align: center !important;
  padding: 100px 0;
  position: relative !important;
}
.text-link {
  color: #334960;
}
.footer__banner--heading p {
  margin: 0 0 3% 0;
}
.main_list {
  display: flex;
  height: 100%;
  justify-content: center;
}
.sub_list {
  display: flex;
  justify-content: flex-end;
  margin-left: 10%;
}
.list {
  padding-bottom: 20px;
}
.footer-inner {
  padding-top: 50px;
}
.footer__banner--wrapper img {
  position: absolute;
  bottom: -36px;
}
.footer__banner--wrapper {
  position: relative !important;
}
.footer-background {
  width: 100%;
  height: auto;
}
.partner-second-row {
  margin-top: 30px;
}
.members-li img {
  height: 40px;
  width: 160px;
}
.fa {
  font-size: medium !important;
}
.partner-second-row {
  margin-top: 30px;
}
img.powerBi {
  width: 107px;
}
@media (min-width: 2560px) {
  .footer__banner--wrapper img {
    bottom: -50px !important;
  }
}
@media (min-width: 1921px) {
  .footer__banner--wrapper img {
    bottom: -37px !important;
  }
}
@media (min-width: 1441px) {
  .footer__banner--wrapper img {
    bottom: -30px !important;
  }
}
@media (min-width: 1025px) and (max-width: 1440px) {
  .footer__banner--wrapper img {
    bottom: -20px;
  }
}

@media (min-width: 1536px) {
  .footer__banner--wrapper img {
    bottom: -30px !important;
  }
}
@media (min-width: 1853px) {
  .footer__banner--wrapper img {
    bottom: -35px !important;
  }
}
@media (min-width: 1600px) {
  .footer__banner--wrapper img {
    bottom: -31px;
  }
}
@media (min-width: 1280px) {
  .footer__banner--wrapper img {
    bottom: -26px;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .footer__banner--wrapper img {
    bottom: -15px;
  }
  .social-icons-ul {
    margin-right: 0;
  }
  .footer-links {
    gap: 20px;
  }
}
@media (max-width: 768px) {
  .footer-left {
    width: 100%;
  }
  .footer-end > div {
    gap: 10px;
  }
  .footer-links {
    justify-content: flex-start;
    flex-direction: column;
    gap: 1rem;
  }

  .footer-bg-block {
    margin-top: -20px;
  }
  .copyright-icon-block {
    padding-bottom: 30px;
  }
  .footer-content hr.separation-line {
    margin: 20px 0;
  }
  .footer-content-inner {
    padding: 40px 0 10px 0;
  }
  .apply-for-invite-txt {
    padding: 0 15px;
  }
  .main_list {
    flex-direction: column;
    margin-top: 20px;
    justify-content: inherit;
  }
  .footer-end {
    padding-top: 20px;
  }
  .footer-items {
    padding-bottom: 30px !important;
  }
  .copyright p {
    text-align: left;
  }

  .footer__banner--wrapper img {
    margin-top: 0;
    display: none;
  }
  .footer__banner--inner {
    padding: 50px 0;
  }
  .social-icons-ul {
    justify-content: center;
    margin-right: 0;
  }
  .list-name {
    margin-top: 20px;
    width: 100%;
  }
  .row .col:last-child {
    margin-left: 0 !important;
  }
  .col-sm-9.copyright {
    padding-bottom: 10px;
  }
  .sub_list {
    margin: 0;
    justify-content: inherit;
  }
  .list {
    padding-bottom: 10px;
  }
}
@media (max-width: 425px) {
  .members-ul {
    flex-direction: column;
  }
  .members-li {
    margin-top: 25px;
  }
  .partner-second-row {
    margin-top: 0;
  }
}
@media (max-width: 768px) {
  .footer-items p {
    width: 100%;
  }
  .footer-logo img {
    height: 50px;
  }
}
@media (max-width: 400px) {
  .list-name p,
  .copyright p,
  .footer-links p {
    font-size: 13px;
  }

  .footer-logo-text h2 {
    font-size: 3rem;
  }
}
