/* Banner  */
.banner-section {
  position: relative;
  z-index: 1;
}
.banner-content-wrapper {
  padding-top: 10rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  z-index: 1;
}

.banner-section h1 {
  font-weight: 400;
  transform: translateY(50px);
  opacity: 0;
  transition: 0.6s;
}
.banner-content-wrapper p {
  color: #7b7583;
  font-size: 22px;
  width: 60%;
}
.banner-content-wrapper > p {
  transform: translateY(50px);
  transition: 0.6s;
  opacity: 0;
}
.banner-button-wrapper {
  margin-top: 5rem;
  transform: translateY(50px);
  transition: 0.6s;
  opacity: 0;
}
.banner-button {
  background: #f75709;
  border: 1px solid #000;
  border-radius: 50px;
  display: inline-flex;
  gap: 1rem;
  align-items: center;
  padding: 2rem 5.5rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 0.4s ease-in-out;
  z-index: 10;
  cursor: pointer;
  transform: scale(1.05);
  box-shadow: 1px 1px 0px 0px #000000,
    /* bottom-right shadow */ -1px 1px 0px 0px #000000,
    /* bottom-left shadow */ 1px -1px 0px 0px #000000,
    /* top-right shadow */ -1px -1px 0px 0px #000000; /* top-left shadow */
}
.banner-button:hover {
  transform: scale(1);
  box-shadow: none;
}
.banner-button p {
  font-size: 1.7rem;
  font-weight: 700;
  margin: 0;
  width: 100% !important;
  color: #fff;
}
/* banner background images */
.banner-section::before {
  content: "";
  position: absolute;
  background: url(/src/assets/BannerSection/banner-bg-before.svg);
  bottom: 25%;
  left: 0;
  height: 258px;
  width: 314px;
  opacity: 0;
  transition: 0.6s;
}
.banner-section::after {
  content: "";
  position: absolute;
  background: url(/src/assets/BannerSection/banner-bg-after.svg);
  top: 23%;
  right: 0;
  height: 258px;
  width: 314px;
  opacity: 0;
  transition: 0.6s;
}
.animate::before,
.animate::after {
  transition-delay: 0.9s;
  opacity: 1;
}

.banner-cards-wrapper {
  justify-content: space-between;
  margin: 12rem auto;
  display: flex;
  width: 58.6%;
  opacity: 0;
  transition: 0.6s;
}
.banner-cards-wrapper::before {
  content: "";
  position: absolute;
  background: url(/src/assets/BannerSection/banner-cards-before.svg);
  width: 224px;
  height: 217px;
  right: 0;
  bottom: 10%;
  background-position: center;
  background-size: contain;
  z-index: 2;
}
.banner-cards-wrapper::after {
  content: "";
  position: absolute;
  background: url(/src/assets/BannerSection/banner-cards-bg.svg);
  height: 192px;
  right: 0;
  bottom: 0;
  left: 0;
  background-position: center;
  background-size: contain;
}
.animate .banner-cards-wrapper {
  opacity: 1;
  transition-delay: 1.2s;
}
.banner-card {
  width: 32%;
  padding: 5rem 3rem 3.5rem;
  display: flex;
  flex-direction: column;
  border: 1px solid #000000;
  background: #fff2d9;
  border-radius: 32px;
  gap: 2.5rem;
  transition: all 0.4s ease-in-out;
  position: relative;
  z-index: 2;
}
.banner-second-card {
  padding: 4rem 3rem 1.5rem;
  align-self: flex-end;
  gap: 1rem;
}
.banner-card img {
  width: 5rem;
}
.banner-card h2 {
  font-size: 2.8rem;
  margin: 0;
}
.banner-card p {
  font-size: 1.8rem;
  color: #2e2e2e;
}
.banner-card:hover {
  transform: translate(-3px, -5px);
  box-shadow: 8px 8px 0px 0px #000000;
  cursor: default;
}
/* Animate */
.animate h1 {
  transform: translateY(0px);
  opacity: 1;
}
.animate .banner-content-wrapper > p {
  transform: translateY(0px);
  opacity: 1;
  transition-delay: 0.3s;
}
.animate .banner-button-wrapper {
  transform: translateY(0px);
  opacity: 1;
  transition-delay: 0.6s;
}
.animate .right-side {
  transform: scale(1);
}

@media (max-width: 1640px) {
  .banner-section::after {
    top: 29%;
  }
  .banner-cards-wrapper {
    width: 77%;
  }
  .banner-cards-wrapper::before {
    bottom: 22%;
  }
}
@media (max-width: 1440px) {
  .banner-section::after {
    width: 229px;
  }
  .banner-cards-wrapper {
    width: 80%;
  }
}
@media (max-width: 1130px) {
  .banner-cards-wrapper {
    width: 90%;
  }
}
@media (max-width: 1024px) {
  .banner-section::after {
    background-size: contain;
    background-repeat: no-repeat;
    top: 36%;
    height: 150px;
    width: 150px;
  }
  .banner-section::before {
    background-size: contain;
    background-repeat: no-repeat;
    height: 200px;
    width: 200px;
  }
  .banner-cards-wrapper::before {
    background-size: contain;
    background-repeat: no-repeat;
    height: 200px;
    width: 200px;
    bottom: 25%;
  }
  .banner-cards-wrapper {
    width: 100%;
    padding: 0 25px;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .banner-cards-wrapper {
    max-width: 540px;
  }
}
@media (max-width: 768px) {
  .banner-content-wrapper {
    text-align: left;
    align-items: flex-start;
  }
  .banner-content-wrapper p {
    width: 95%;
  }
  .banner-cards-wrapper {
    flex-direction: column;
    gap: 2rem;
  }
  .banner-card {
    width: 100%;
    padding: 5rem 3rem 3.5rem;
  }
  .banner-section::before {
    bottom: 41%;
  }
  .banner-section::after {
    content: none;
  }
  .banner-cards-wrapper::after {
    height: 570px;
  }
  .banner-cards-wrapper::before {
    bottom: 41%;
  }
}
@media (max-width: 400px) {
  .banner-section h1 {
    font-size: 5.5rem;
  }
  .banner-section::before,
  .banner-cards-wrapper::before {
    content: none;
  }
  .banner-cards-wrapper::after {
    height: 650px;
  }
}
