/*  */
.use-cases-section {
  background: #eeedff;
  padding: 8rem 0;
}
.section-heading {
  text-align: center;
}
.section-heading p {
  font-size: 1.8rem;
}
.section-heading p {
  width: 58%;
  margin: 0 auto;
}

.second-tab-switch-wrapper {
  margin-top: 6rem;
  padding: 0 2rem;
}
.second-tab-switch-content {
  display: none;
  animation: fadeEffect 1s;
}
.second-tab-switch-content.active {
  display: block;
}
.tab-links-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 5rem auto;
  gap: 1rem;
}
.tab-links-wrapper div {
  background: #fff;
  border: 1px solid #000000;
  box-shadow: 5px 5px 0px 0px #000000;
  transform: translate(-3px, -5px);
  border-radius: 12px;
  padding: 2rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
}
.tab-links-wrapper div:hover {
  box-shadow: none;
  transform: translate(0);
}
.tab-links-wrapper div.active {
  background: #24605a;
  box-shadow: none;
  transform: translate(0);
}
.tab-links-wrapper p {
  margin: 0;
  color: #111827;
}
.tab-links-wrapper div.active p {
  color: #fff;
}
.second-tab-switch-cards {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 95%;
}
.tab-switch-card {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 3rem 3rem 0;
  border-radius: 12px;
  border: 1px solid #000000;
  position: relative;
  transition: all 0.3s ease;
  z-index: 0;
}
.first-card {
  background: #fdf374;
}
.second-card {
  background: #f39460;
}
.third-card {
  background: #455778;
}
.card-text,
.card-image {
  position: relative;
  z-index: 1;
}
.card-text h3 {
  font-size: 2.4rem;
  color: #fff;
  margin-bottom: 2rem;
  font-weight: 500;
}
.first-card h3 {
  color: #111827;
}
.second-card p,
.third-card p {
  color: #fff;
}
.tab-switch-card::after {
  content: "";
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: -12px;
  right: -12px;
}
.tab-switch-card:hover {
  transform: translate(-3px, -5px);
  box-shadow: 12px 12px 0px 0px #000000;
}
@keyframes fadeEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media (max-width: 1440px) {
  .second-tab-switch-cards {
    width: 100%;
  }
}
@media (min-width: 1025px) and (max-width: 1200px) {
  .tab-links-wrapper {
    width: 100%;
  }
  .tab-links-wrapper div {
    padding: 1.5rem;
  }
}
@media (max-width: 1024px) {
  .tab-links-wrapper {
    width: 100%;
  }
  .section-heading p {
    width: 70%;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .tab-links-wrapper div {
    width: 22%;
  }
}
@media (max-width: 768px) {
  .tab-links-wrapper {
    width: 100%;
    flex-direction: column;
    gap: 2.5rem;
  }
  .tab-links-wrapper div {
    width: 75%;
  }
  .second-tab-switch-cards {
    flex-direction: column;
    gap: 7rem;
  }
  .second-tab-switch-cards div {
    width: 100%;
  }
}
