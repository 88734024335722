.solution-section {
  background: #fff5ec;
  padding: 10rem 0;
}
.solution-section .section-heading {
  text-align: left;
}
.heading-tag {
  display: inline-block;
  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 40px;
  padding: 1rem 2rem;
  margin-bottom: 2rem;
}
.heading-tag span {
  font-size: 1.4rem;
  font-family: work-sans-bold;
  color: #111827;
}
.heading-description {
  display: flex;
  flex-direction: column;
}

.solution-section .section-heading p {
  font-size: 1.8rem;
  width: 100%;
}
div[class*="column-"] {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.column-1 {
  justify-content: space-between;
}
.solutions-card {
  padding: 2rem 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border: 1px solid #000000;
  transition: 0.35s;
  transform: translate(-3px, -5px);
  box-shadow: 5px 5px 0px 0px #000000;
  border-radius: 32px;
}
.solutions-card img {
  width: fit-content;
}
.solutions-first-card {
  background: #4681ff;
}
.solutions-second-card {
  background: #fab436;
}
.solutions-third-card {
  background: #f5f5f5;
}
.solutions-fourth-card {
  background: #f75709;
}
.solutions-third-card img:nth-child(4) {
  align-self: flex-end;
}
.solutions-fifth-card {
  background: #ffe7ba;
  height: 100%;
}
.solutions-card:hover {
  box-shadow: none;
  cursor: default;
  transform: translate(0);
}
.solutions-first-card h3,
.solutions-first-card p {
  color: #fff;
}
@media (max-width: 1024px) {
  .column-1 {
    flex-direction: row !important;
  }
  .column-1 > div {
    width: 48%;
  }
  div[class*="column-"] {
    gap: 3rem;
  }
}
@media (max-width: 768px) {
  .column-1 {
    flex-direction: column !important;
  }
  .column-1 > div {
    width: 100%;
  }
  .column-2 {
    flex-direction: column-reverse !important;
  }
  .section-wrapper.row {
    gap: 3rem;
  }
}
@media (max-width: 768px) {
  .heading-description {
    flex-direction: column;
    gap: 2rem;
  }
  .section-heading {
    padding-bottom: 2rem;
  }
  .heading-description h2,
  .heading-description p {
    width: 100%;
    margin: 0;
  }
  .tab-switch-inner {
    flex-direction: column;
    gap: 3rem;
    padding: 5rem 3rem;
  }
  .tab-switch-image,
  .tab-switch-text {
    width: 100%;
  }
  .tab-links-container {
    flex-direction: column;
  }
  .tab-links-container div {
    width: 75%;
    padding-bottom: 0;
    margin-bottom: 4rem;
  }
}
@media (max-width: 576px) {
  .tab-switch-text h3 {
    font-size: 3.5rem;
  }
}
@media (max-width: 456px) {
  .tab-links-container div {
    width: 100%;
  }
}
