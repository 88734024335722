@font-face {
  font-family: "work-sans-bold";
  src: url("https://8162471.fs1.hubspotusercontent-na1.net/hubfs/8162471/work-sans/WorkSans-Bold.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "work-sans-extrabold";
  src: url("https://8162471.fs1.hubspotusercontent-na1.net/hubfs/8162471/work-sans/WorkSans-ExtraBold.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "work-sans-semibold";
  src: url("https://8162471.fs1.hubspotusercontent-na1.net/hubfs/8162471/work-sans/WorkSans-SemiBold.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "work-sans-medium";
  src: url("https://8162471.fs1.hubspotusercontent-na1.net/hubfs/8162471/work-sans/WorkSans-Medium.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "work-sans-regular";
  src: url("https://8162471.fs1.hubspotusercontent-na1.net/hubfs/8162471/work-sans/WorkSans-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "work-sans-light";
  src: url("https://8162471.fs1.hubspotusercontent-na1.net/hubfs/8162471/work-sans/WorkSans-Light.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html {
  font-family: "work-sans-medium", sans-serif;
  font-size: 10px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-size: 1.6rem;
  line-height: 1.5;
  color: #334960;
  background-color: #f6f6f6;
  margin: 0;
  overflow-x: hidden;
  height: 100%;
  padding: 0;
}

h1 {
  font-size: 7rem;
}

h2 {
  font-size: 4rem;
  font-weight: 500;
  line-height: 150%;
}

h3 {
  font-size: 2.6rem;
  font-weight: 500;
  line-height: 150%;
}
h4 {
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 150%;
}

h1 {
  color: #000000;
}
h2 {
  color: #000000;
}
h3,
h4 {
  color: #000;
}
h1 {
  font-family: "work-sans-bold", sans-serif;
  margin: 0 0 2rem;
}
h2 {
  font-family: "work-sans-semibold", sans-serif;
  margin: 0 0 2rem;
}
h3,
h4,
h5 {
  font-family: "work-sans-medium", sans-serif;
  margin: 0;
}
p,
span {
  color: #1f2534;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 150%;
  font-family: "work-sans-regular", sans-serif;
  margin: 0 0 2rem;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

mark {
  background: #ff0;
  color: #000;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

svg:not(:root) {
  overflow: hidden;
}

hr {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eeeeee;
}

pre {
  overflow: auto;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button,
select {
  text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

input,
button,
select,
textarea {
  font-family: "work-sans-medium", sans-serif;
  font-size: inherit;
  line-height: inherit;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  display: block;
}

a {
  text-decoration: none;
  font-family: "work-sans-medium", sans-serif;
  font-weight: 400;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
}

figure {
  margin: 0;
}

img {
  vertical-align: middle;
  max-width: 100%;
}

@media (max-width: 1024px) {
  html {
    font-size: 8px;
  }
}
