.features-cards-wrapper {
  margin-top: 7rem;
  row-gap: 3rem;
  justify-content: center;
}
.features-card {
  padding: 2.5rem;
  background: #ffffff;
  border-radius: 32px;
  border: 1px solid #000000;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  z-index: 0;
}
.features-card img {
  width: fit-content;
  flex-shrink: 0;
  z-index: 1;
}

@media (max-width: 1440px) {
  .round-orange {
    margin-bottom: 5rem;
    margin-left: -5rem;
  }
}
@media (max-width: 1100px) {
  .round-orange {
    margin-bottom: 5rem;
    margin-left: -7rem;
  }
}

.features-first-card {
  height: 100%;
}
.features-first-card img {
  border-radius: 12px;
  width: 100%;
}
.features-second-card {
  height: 100%;
  justify-content: center;
  background: #d7e5ff;
}
.features-third-card {
  background: #067240;
  position: relative;
}
.features-third-card::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: url(../../assets/FeatureSection/feature-card-3-after.svg)
    no-repeat;
  background-position: center;
  border-radius: 0 0 30px 0;
}
.features-fourth-card {
  background: #fff4dd;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}
.features-fifth-card {
  background: #ffcce2;
  height: 100%;
}

.features-cards-wrapper .column-1 {
  margin: 0;
}
.column-1 .features-first-card {
  width: 100%;
}
