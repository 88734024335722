header {
  position: fixed;
  top: 0;
  z-index: 11;
  left: 0;
  right: 0;
}

header nav {
  display: flex;
  justify-content: space-between;
}

.pricing-header {
  padding: 2rem 0;
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 11;
  left: 0;
  right: 0;
  border-bottom: 1px solid #cfcfcf;
  /* background: #ffffff45;
  backdrop-filter: blur(12px); */
}
.elixir-logo span {
  font-size: 3.4rem;
  font-weight: 500;
  color: #fff;
}
header.active {
  background-color: #fff;
  box-shadow: 0 0 7px #573bd34d;
}

ul.headder-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60%;
  padding: 0 2rem;
}

.elixirdata-hover-element-header p {
  margin: 0 2rem;
  font-weight: 500;
  font-size: 21px;
  color: #000;
}

.redirection-active p {
  background: #f75709;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}
.elixir-usecase-textside p {
  margin: 0 !important;
}
.secondary-header-button {
  background: transparent;
  border: 1px solid #235af4;
  border-radius: 6px;
  color: #213047;
  display: inline-block;
  font-family: 'work-sans-medium', sans-serif;
  font-size: 2rem;
  font-weight: 400;
  margin-right: 20px;
  min-height: 40px;
  padding: 10px 30px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
  z-index: 10;
  width: max-content;
  margin-left: 3rem;
}
.secondary-header-button:hover {
  color: #000 !important;
}
.primary-button-elixir:hover {
  color: #fff !important;
}
.primary-button-elixir {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 29px;
  background: #235af4;
  color: #fff;
  border-radius: 6px;
  font-family: 'work-sans-medium', sans-serif;
  width: max-content;
  font-size: 2rem;
  font-weight: 400;
  cursor: pointer;
}
.primary-button-elixir.start svg {
  -webkit-animation: stroke 1s linear;
  animation: stroke 1s linear;
}

.primary-button-elixir.lines {
  position: absolute;
  inset: 0;
  mix-blend-mode: hard-light;
  pointer-events: none;
  z-index: 1;
}
.primary-button-elixir .lines > div {
  position: absolute;
  inset: 0;
}
.primary-button-elixir .lines > div:last-child {
  transform: rotate(180deg);
}
.primary-button-elixir .lines > div svg {
  display: block;
  position: absolute;
  inset: 0;
  overflow: visible;
  fill: none;
  stroke-width: 2;
  stroke: #c9e9ff;
  width: 100%;
  height: 100%;
  stroke-dasharray: 2 10;
  stroke-dashoffset: 14;
  opacity: 0;
}

@-webkit-keyframes stroke {
  30%,
  55% {
    opacity: 1;
  }
  100% {
    stroke-dashoffset: 4;
    opacity: 0;
  }
}
@keyframes stroke {
  30%,
  55% {
    opacity: 1;
  }
  100% {
    stroke-dashoffset: 4;
    opacity: 0;
  }
}
.secondary-button-elixir {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 29px;
  color: #2043df;
  border-radius: 32px;
  font-family: 'work-sans-medium', sans-serif;
  width: max-content;
  font-size: 2rem;
  border: 2px solid #2043df;
  font-weight: 400;
  cursor: pointer;
  margin-left: 20px;
}
.colored-button-elixir {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 29px;
  background-color: #2043df;
  border-radius: 32px;
  font-family: 'work-sans-medium', sans-serif;
  width: max-content;
  font-size: 2rem;
  border: none;
  font-weight: 400;
  color: #fff;
  margin-top: 50px;
}
header nav ul p {
  font-size: 16px;
  font-weight: 400;
  color: #2b3751;
  font-family: 'work-sans-medium', sans-serif;
}

.mobile-navigation-header {
  display: none;
  align-items: center;
  justify-content: space-between;
}
.mobile-active {
  background-color: #fff;
}

.quality-button {
  margin-top: 20px;
  margin-left: 13px;
}

@media (max-width: 1024px) {
  header nav {
    display: none;
  }
  header {
    background-image: none !important;
    padding-top: 10px;
  }
  .elixir-logo img {
    height: 50px;
  }
  header nav ul {
    align-items: flex-start;
  }
  .mobile-navigation-header {
    display: flex;
  }
}
@media (max-width: 767px) {
  .quality-button {
    margin-left: 14px !important;
    padding: 6px 19px !important;
    margin-top: 20px;
  }
  .hamburger {
    display: block;
  }
  .primary-button-elixir {
    padding: 10px 20px;
  }
  .secondary-header-button {
    margin-left: 0;
  }
  .elixirdata-hover-element-header p {
    display: none;
  }
  .main-navigation__menu ul li:last-of-type {
    margin: 0 !important;
  }
}
@media (max-width: 767px) {
  .mobile-navigation-header {
  }
}
@media (max-width: 576px) {
  .mobile-navigation-header {
  }
}
.main-navigation__menu {
  height: calc(100vh - 30px);
  position: absolute;
  left: 0;
  right: 0;
  z-index: -1;
  overflow: auto;
  opacity: 0;
  visibility: hidden;
  display: flex;
  background-color: #fff;
  display: none;
}
.main-navigation__menu-active {
  z-index: 1000;
  opacity: 1;
  visibility: visible;
  display: block;
}
.main-navigation__menu ul {
  flex-direction: column;
  overflow-y: scroll;
  padding: 30px 0 70px;
  overflow-x: hidden;
}
.main-navigation__menu ul li {
  width: 100%;
  border-bottom: 1px solid rgba(91, 68, 35, 0.1);
  padding: 1rem;
}
.main-navigation__menu ul li:last-of-type {
  border-bottom: none;
}
.main-navigation-accordion-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.main-navigation-accordion-heading img {
  width: 32px;
  transition: 0.5s ease-in-out;
}
.main-navigation__menu ul li p {
  font-size: 2.4rem;
  cursor: pointer;
  font-weight: 500;
  margin: 0;
}
.main-navigation-accordion-heading {
  cursor: pointer;
}
.main-navigation-accordion.main-navigation-accordion-active
  .main-navigation-accordion-heading
  img {
  transform: rotate(180deg);
}
.main-navigation-accordion.main-navigation-accordion-active
  .main-navigation-accordion-heading
  p {
  color: #2043df;
}
.main-navigation-accordion-list {
  display: none;
  margin-top: 20px;
}
.main-navigation-accordion-list p a {
  font-weight: 400;
  color: #2b3751;
  font-size: 2.3rem;
  font-family: 'work-sans-medium', sans-serif;
  margin-top: 10px;
  display: block;
}
.main-navigation-accordion.main-navigation-accordion-active
  .main-navigation-accordion-list {
  display: block;
}
/* hamburger css */
.mobile-hamburger {
  width: 25px;
  margin-bottom: 3px;
}
.hamburger .line {
  height: 2px;
  background-color: #555555;
  display: block;
  margin: 5px auto;
  -webkit-transition: transform 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  border-radius: 5px;
}
.hamburger:hover {
  cursor: pointer;
}
.is-activeHamburger #hamburger-6 {
  -webkit-transition: transform 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.is-activeHamburger #hamburger-6 .line:nth-child(2) {
  width: 0px;
}
.is-activeHamburger #hamburger-6 .line:nth-child(1),
.is-activeHamburger #hamburger-6 .line:nth-child(3) {
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
.is-activeHamburger #hamburger-6 .line:nth-child(1) {
  -webkit-transform: translateY(11px);
  -ms-transform: translateY(11px);
  -o-transform: translateY(11px);
  transform: translateY(11px);
}
.is-activeHamburger #hamburger-6 .line:nth-child(3) {
  -webkit-transform: translateY(-3px) rotate(90deg);
  -ms-transform: translateY(-3px) rotate(90deg);
  -o-transform: translateY(-3px) rotate(90deg);
  transform: translateY(-3px) rotate(90deg);
}

/* ----------------------------------------------------------------------------------------- */
/* header dropdown */
.elixirdata-hover-element-header > p {
  cursor: pointer;
}
.elixirdata-dropdown-wrapper {
  position: absolute;
  opacity: 0;
  width: 100%;
  left: 0;
  right: 0;
  top: 86px;
  z-index: 999;
  box-shadow: 0px 3px 6px #1860d333;
  border-top: 1px solid rgba(91, 68, 35, 0.1);
  background-color: #fff;
  visibility: hidden;
  transform: translateY(50px);
}
li.elixirdata-hover-element-header:hover .elixirdata-dropdown-wrapper {
  box-shadow: 0 3px 6px rgb(57 71 96 / 40%);
  position: absolute;
  visibility: visible;
  transform: translateY(0);
  opacity: 1;
  transition: 0.4s ease all;
  padding-bottom: 6rem;
}
li.elixirdata-hover-element-header:hover > p {
  color: #2043df;
}
.elixirdata-dropdown-row {
  padding: 90px 0 0;
}
.elixirdata-usecase-cards-wrapper {
  display: flex;
  align-items: flex-start;
  margin-bottom: 90px;
}
.elixir-usecase-imageside {
  margin-right: 15px;
  flex-shrink: 0;
}
.elixir-usecase-imageside img {
  width: 140px;
}

.elixir-usecase-textside:hover a p {
  text-decoration: underline;
}
.elixir-usecase-textside a p {
  font-weight: 500;
  font-size: 2.3rem;
  margin-bottom: 8px;
}
.elixir-usecase-textside > p {
  font-weight: 400;
  font-size: 2rem;
}
/* --------------Solution-drop-down-css------------*/

ul.dropdown-solution {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.elixirdata-solution-cards-wrapper {
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
  flex-direction: column;
  border-right: 1px solid #e9e9e9;
  height: 100%;
}
.elixir-usecase-textside-solution a p:hover {
  text-decoration: underline;
}
.solution-left-panel-item {
  border-radius: 50px;
  padding: 10px 15px;
  cursor: pointer;
}
.solution-left-panel-item p {
  font-weight: 500;
  font-size: 2.4rem;
}
.left-tab-panel-active {
  background-color: #ebecee;
  border-radius: 10px;
  padding: 10px 20px;
  width: 30rem;
}
.elixir-link:hover p {
  text-decoration: underline;
}
.technology-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.product-partner-list-hover {
  display: none;
}
.industry-partner-list-hover {
  display: none;
}
.product-container {
  display: flex;
  flex-wrap: wrap;
}

.industry-container {
  display: flex;
}
.industry-container {
  display: flex;
  flex-wrap: wrap;
}
.elixirdata-usecase-industry-cards-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 42px;
}
.dropdown-solution a li {
  padding: 0px;
  margin-bottom: 25px;
}
.elixer-linked-text:hover {
  text-decoration: underline;
}
.accordion.accordion-active .main-navigation-accordion-heading img {
  transform: rotate(180deg);
}
.accordion.accordion-active .main-navigation-accordion-heading p {
  color: #2043df;
}
.elixirdata-usecase-technology-cards-wrapper {
  margin: 0 25px 40px 0;
  width: 40%;
}
.elixirdata-usecase-industry-cards-wrapper p {
  width: 75%;
}
.accordion-list {
  display: none;
  padding: 0px 0px 20px;
  margin-bottom: 20px;
}
.accordion-border {
  border-bottom: 1px solid rgba(91, 68, 35, 0.1);
}
.elixirdata-usecase-product-cards-wrapper {
  display: flex;
  margin-bottom: 30px;
  width: 33%;
  align-content: center;
  align-items: center;
  margin-right: 2px;
}
.elixir-usecase-imageside img {
  width: 56px;
}
.elixirdata-solution-dropdown-row {
  padding: 34px 0 0;
}
.elixir-solution-dropdown-row {
  padding: 35px 0 0;
}
.partners-hover-list-item .elixir-usecase-textside > p {
  font-size: 2rem;
  width: 90%;
}
img.secondary-dropdown-arrow {
  opacity: 0.7;
}
.industry-content-container {
  display: flex;
}
.technology-partner-list {
  padding-top: 25px;
}
.product-partner-list {
  padding-top: 17px;
}
.industry-partner-list {
  padding-top: 25px;
}
.dropdown-solution li {
  margin-right: 10px;
}
.main-navigation-accordion.main-navigation-accordion-active
  .main-navigation-accordion-list {
  display: block;
}

@media (max-width: 1024px) and (min-width: 1024px) {
  .left-tab-panel-active {
    width: 22.3rem;
  }
}

/* resource drop-down css */
.resources-header-right-section-wrapper {
  background-color: #f3f4f5;
}
.resources-usecase-textside p {
  margin-bottom: 25px;
}
.all-resource {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 93px 0 0;
}
.all-resource img {
  margin-left: 20px;
}
.resources-usecase-cards-wrapper {
  margin-top: 40px;
}
.resources-header-right-section {
  padding: 40px 70px 20px;
}
.learn-more {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.all-resource p {
  margin-bottom: 0px !important;
  font-weight: 600;
}
.learn-more img {
  margin-left: 20px;
}
.resources-header-section {
  padding: 0 0 20px;
  border-bottom: 1px solid #d9d9d9;
}
.resources-suggestion-header {
  padding: 25px 0;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}
.suggested-resource p {
  font-size: 1.8rem;
  font-weight: 300;
  margin-bottom: 10px;
  opacity: 0.5;
}
.suggestion-image img {
  width: 90%;
  border-radius: 5px;
}
.suggestion-image {
  width: 100%;
}
.recommended-div p {
  margin: 8px 0 12px;
  opacity: 0.5;
  font-size: 1.8rem;
  font-weight: 300;
}
@media (max-width: 1440px) {
  .resources-suggestion-header {
    padding: 35px 0;
  }
  .resources-header-section {
    padding: 0 0 30px;
  }
  .suggestion-image img {
    width: 100%;
  }
}
@media (max-width: 1200px) {
  ul.headder-content {
    width: 66%;
  }
}
@media (max-width: 1024px) {
  .resources-suggestion-header {
    padding: 40px 0 0;
  }
  .resources-header-section {
    padding: 0 0 37px;
  }
  .all-resource {
    margin: 48px 0 0;
  }
  .resources-suggestion-header {
    padding: 24px 0 0;
  }
  .resources-header-section {
    padding: 0 0 20px;
  }
}
li.main-navigation-accordion.main-navigation-accordion-active:active,
li.main-navigation-accordion.main-navigation-accordion-active:focus,
li.main-navigation-accordion.main-navigation-accordion-active:focus-visible {
  outline: none;
}
.resources-usecase-textside p:hover {
  color: #2043df;
}

.all-resource p:hover {
  color: #2043df;
}
.learn-more p:hover {
  color: #2043df;
}
.pricing:hover {
  color: #2043df;
}
.platform:hover {
  color: #f75709 !important;
}
.suggested-resource-content p:hover {
  color: #2043df;
}
.solution-left-panel-item p {
  margin: 0 !important;
}

@media (max-width: 400px) {
  .mobile-hamburger {
    width: 20px;
    margin-bottom: 0px;
  }
  .elixir-logo span {
    font-size: 2.3rem;
  }
}
