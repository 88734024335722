.benefits-section {
  background: #e5f6dc;
  padding: 8rem 0;
}
.benefits-cards-wrapper {
  margin-top: 7rem;
  row-gap: 2.5rem;
  justify-content: center;
}
.benefits-card-inner {
  display: flex;
  gap: 2rem;
  padding: 2rem;
  background: #ffffff;
  border-radius: 32px;
  border: 1px solid #000000;
  transition: all 0.3s ease;
  transform: translate(-3px, -5px);
  box-shadow: 8px 8px 0px 0px #000000;
  height: 100%;
}
.benefits-card-inner:hover {
  cursor: default;
  transform: translate(0);
  box-shadow: none;
}
.benefits-icon {
  flex-shrink: 0;
}
.benefits-icon img {
  width: 60px;
  height: 52px;
}
.benefits-text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.benefits-text h3 {
  font-size: 2rem;
}
.benefits-text p {
  color: #111827;
}
@media (max-width: 350px) {
  .benefits-card-inner {
    flex-direction: column;
  }
}
